.italic {
  font-style: italic;
}
.grant_access {
  label span,
  p {
    font-family: Inter, Helvetica, 'sans-serif';
  }
  .img-responsive {
    max-width: 150px;
    margin: 0 auto;
  }
  .number {
    font-weight: 600;
    color: #00bf62;
    font-size: 18px;
    margin-top: 0;
  }
}
